<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">
                <tchtree v-model="searchForm.class_id" style="margin: 0 10px 10px 0;"></tchtree>
                <el-input placeholder="学号" v-model="searchForm.stu_num" size="small" class="input-with-select"
                    style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
                <el-input placeholder="姓名" v-model="searchForm.stu_name" size="small" class="input-with-select"
                    style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
                <el-button icon="el-icon-search" style="margin: 0 10px 10px 0;" size="small" type="primary"
                    @click="() => { page.current_page = 1; getList() }">搜索</el-button>
            </div>


        </el-row>
        <div class="dividerBar"></div>

        <el-table v-tableFit :data="tableData" height="100%" size="small" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column label="学号" prop="stu_code" show-overflow-tooltip width="135" align="center"></el-table-column>
            <el-table-column label="姓名" prop="stu_name" width="135" align="center"></el-table-column>
            <el-table-column label="院系" prop="yx_name" min-width="150px" align="center"></el-table-column>

            <el-table-column label="班级" prop="class_name" min-width="150" align="center"></el-table-column>
            <el-table-column label="求职状态" prop="intention_status" min-width="100" align="center">
                <template slot-scope="scope">{{ scope.row.job_status ? '求职中' : '未求职' }}</template>
            </el-table-column>
            <el-table-column label="操作" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-tag @click="handleModify(scope.row)" type="primary" size="small">求职记录</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
            :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
            :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>
        <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" :destroy-on-close="true"
            custom-class="cus_dialog cus_dialogMax" width="1000px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;"><span style="font-weight: 700">{{ stuName }}</span> 求职记录</span>
            </div>
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item v-for="(item, index) in detailList" :name="item.id">
                    <template slot="title">
                        <div class="flexBetween" style="width:100%;">
                            <div style="line-height:unset!important;color:#1289ff;font-weight:700;">{{ item.firm_name }}</div>
                            <div style="">岗位：{{ item.job_name }}</div>
                        </div>
                    </template>
                    <div class="dialog_right">

                        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                            <span></span>
                            <span style="font-weight: 400">职位信息</span>
                        </div>

                        <div class="flexStart"
                            style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">

                            <div class="lineRow flexStretch" style="width:33.3%;">
                                <div class="cell flexStart">薪资</div>
                                <div class="cell flexStart flex_1">{{ item.pay }}</div>
                            </div>
                            <div class="lineRow flexStretch" style="width:33.3%;">
                                <div class="cell flexStart">招聘人数</div>
                                <div class="cell flexStart flex_1">{{ item.need_num }} 人</div>
                            </div>
                            <div class="lineRow flexStretch" style="width:33.3%;">
                                <div class="cell flexStart">工作城市</div>
                                <div class="cell flexStart flex_1">{{ item.cityName }}</div>
                            </div>

                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart">工作地点</div>
                                <div class="cell flexStart flex_1">{{ item.word_addr }}</div>
                            </div>
                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart">发布时间</div>
                                <div class="cell flexStart flex_1">{{ item.time }}</div>
                            </div>
                        </div>
                        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                            <span></span>
                            <span style="font-weight: 400">职位要求</span>
                        </div>
                        <div class="flexStart"
                            style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart">学历</div>
                                <div class="cell flexStart flex_1">{{ item.eduaction }}</div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart">经验</div>
                                <div class="cell flexStart flex_1">{{ item.experience }}</div>
                            </div>
                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart" style="min-height: 40px;height: unset;">职位描述</div>
                                <div class="cell flexStart flex_1"
                                    style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
                                    {{ item.job_desc }}
                                </div>
                            </div>
                        </div>
                    </div>

                </el-collapse-item>

            </el-collapse>


            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogForm = false" type="primary" plain>关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import tchtree from "../../com/tchTree.vue";
import citys from "../../../utils/arae_value.js";
export default {
    components: {
        tchtree
    },
    data() {
        return {
            url:
                "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            srcList: [
                "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
            ],
            searchForm: {
                stu_num: "",
                stu_name: "",
                tel: "",
                sex: "",
                intention_status: "",
                class_id: "",
                year: ""
            },
            tableData: [],
            dialogForm: false,
            formData: {},
            jobHtml: "",
            practiceHtml: "",
            projectHtml: "",
            qzzt: {}, qzztArr: [],
            tdzt: {},
            mszt: {},
            lyzt: {},
            xzfw: {},
            xlyq: {},

            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            stuName: '',
            activeName: '',
            detailList: []
        };
    },
    mounted() {
        this.getComType().then(rr => {
            this.getList();
        });
    },
    methods: {
        getComType() {
            return new Promise(resolve => {
                this.$http
                    .post("/api/sys_param_list", {
                        param_type: "'QZZT','TDZT','LYZT','MSZT','SALARY_RANGE','EDUACTION'",
                        used: 0
                    })
                    .then(res => {
                        let qzzt = {}, qzztArr = [] //求职状态
                        let tdzt = {}; //投递状态
                        let mszt = {}; //面试状态
                        let lyzt = {}; //录用状态
                        let xzfw = {}; //薪资类型
                        let xlyq = {}; //学历类型

                        for (let item of res.data) {
                            if (item.param_type == "SALARY_RANGE") {
                                xzfw[item.param_value] = item.param_desc;
                            }
                            if (item.param_type == "QZZT") {
                                qzzt[item.param_value] = item.param_desc;
                                qzztArr.push(item)
                            }
                            if (item.param_type == "TDZT") {
                                tdzt[item.param_value] = item.param_desc;
                            }
                            if (item.param_type == "MSZT") {
                                mszt[item.param_value] = item.param_desc;
                            }
                            if (item.param_type == "LYZT") {
                                xzfw[item.param_value] = item.param_desc;
                            }
                            if (item.param_type == "EDUACTION") {

                                xlyq[item.param_value] = item.param_desc;
                            }
                        }
                        this.qzzt = qzzt; this.qzztArr = qzztArr
                        this.tdzt = tdzt;
                        this.mszt = mszt;
                        this.lyzt = lyzt;
                        this.xzfw = xzfw;
                        this.xlyq = xlyq;

                        resolve();
                    });
            });
        },
        getList() {
            let data = {};
            data.stu_num = this.searchForm.stu_num;
            data.stu_name = this.searchForm.stu_name;
            data.class_id = this.searchForm.class_id;
            data.page = this.page.current_page;
            data.pagesize = this.page.per_page;
            this.$http.post("/api/stu_job_search_list", data).then(res => {
                this.tableData = res.data.data;
                this.page = res.data.page;
            });
        },
        getParentId(list, iid) {
            if (list && list.length > 0) {
                for (let o of list) {
                    if (o.value == iid) return o;
                    const o_ = this.getParentId(o.children, iid);
                    if (o_) return o_;
                }
            }
        },
        canParseToJson(str, parseFlag) {
            try {
                if (typeof JSON.parse(str) === "object" && ((Object.prototype.toString.call(JSON.parse(str)) === "[object Object]") ||
                    (Object.prototype.toString.call(JSON.parse(str)) === "[object Array]"))) {
                    return parseFlag === true ? JSON.parse(str) : true
                }
            } catch (e) { }
            return false
        },
        handleModify(row) {
            this.stuName = row.stu_name
            this.$http.post("/api/stu_delivery_list", { stu_id: row.id, page: 1, pagesize: 1000000 }).then(res => {


                res.data.data.map(a => {
                    let obj = this.getParentId(citys, a.city);
                    a.cityName = obj ? obj.label : '';
                })



                this.detailList = res.data.data;
                this.dialogForm = true;
            });
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },
        openAnnexResume(url) {
            window.open(url, "_blank")
        },
    }
};
</script>

<style scoped lang="less">
.cus_dialog {

    div {
        box-sizing: border-box;
    }

    .rowContainer {
        width: 100%;

        .item-userinfo {
            margin-top: 32px;

            .color30 {
                color: #303133;
            }

            .color60 {
                color: #606266;
            }

            .fontWeight500 {
                font-weight: 700;
            }

            .left {
                width: 100px;
            }

            .right {
                margin-left: 32px;

                .userName {
                    font-size: 18px;
                    color: #303133;
                    font-weight: 500;
                    margin-right: 4px;
                }

                .contact {
                    margin-top: 8px;
                }

                .timestamp {
                    color: #909399;
                    font-size: 12px;
                }

                .commonText {
                    margin-top: 8px;
                }

                .annexResume {
                    margin-top: 8px;
                    color: #3378fa;
                    cursor: pointer;
                }

                .item-att {
                    margin-left: 8px;
                    color: #3378fa;
                    cursor: pointer;
                }
            }
        }

        .item-userinfo:first-of-type {
            margin-top: 0;
        }

        .subitem-userinfo {
            margin-top: 16px;
            color: #606266;
        }

        .crumbImg {
            width: 100%;

            .thumbnail {
                display: inline-block;
                width: 20%;

                img {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }

    .avatarContainer {
        flex: 0 1 68px;

        &>span {
            border-radius: 68px;
            display: block;
            width: 68px;
            height: 68px;
            line-height: 68px;
        }

        .ant-avatar.ant-avatar-icon {
            font-size: 40px;
        }

        img {
            display: inline-block;
            width: 68px;
            height: 68px;
            border-radius: 50%;
        }
    }

    .right {
        .ant-divider-vertical {
            margin: 0 16px;
        }
    }

    .dialog_right {
        .lineRow {
            .cell:first-of-type {
                width: 120px;
            }

        }
    }
}
</style>